export const environment = {
  BUILD_ENV: 'local',
  production: false,
  development: false,
  local: true,
  BASE_URL: 'https://api-stream-pf35w2ahoq-uc.a.run.app/',
  BASE_URL_PRODUCT: 'https://api-product-dev.predigle.com/',
  BASE_URL_ORDER: 'https://api-entity-order-pf35w2ahoq-uc.a.run.app/',
  BASE_URL_PRECOG: 'http://localhost:5000/',
  LUNA_CLIENT_ID: 'luna',

  keycloak: {
    authority: 'https://accounts.predigle.com',
    // redirectUri: 'https://stream-dev.predigle.com/',
    // postLogoutRedirectUri: 'https://stream-dev.predigle.com/',
    redirectUri: 'http://localhost:4200',
    postLogoutRedirectUri: 'http://localhost:4200',
    realm: 'predigle-dev',
    clientId: 'stream-frontend',
    groupId: 'd1dd0c7a-f957-47c3-b061-12c7b48af315'
  },
  idleConfig: { idle: 10, timeout: 60, ping: 10 },
};