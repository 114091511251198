import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { StreamTree } from '../../../common/interface/common.interface';
import { LaneMonitor } from '../../../pages/live-console/interface/live-console.interface';
import { HistoryView, InfoDivertLane, InfoLiveConsole, InfoLiveConsoleWaveMetaData } from '../../interface/common.interface';
import { historyViewNav } from '../../../layout/side-nav/interface/side-nav.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private openSideNav = new BehaviorSubject<boolean>(false);
  public viewMode = new BehaviorSubject<boolean>(false);
  public streamID: string | undefined = undefined;
  public $streamID: Subject<string | undefined> = new Subject();
  public $changeStreamID: Subject<string | undefined> = new Subject();
  public isLoading: boolean = false;
  public searchProgress = new Subject<any>();
  public currentViewPage = new Subject<any>();
  public trackStreamTree: StreamTree[] = [];
  public progressType: string = '';
  public viewChange: Subject<boolean> = new Subject<boolean>();
  public LaneMonitorLiveConsole: Subject<string[]> = new Subject<string[]>();
  public historyViewChecked: Subject<HistoryView> = new Subject<HistoryView>();
  public historyView: Subject<historyViewNav> = new Subject<historyViewNav>();
  public infoLiveConsole: Subject<InfoLiveConsole | null> =
    new Subject<InfoLiveConsole | null>();
  public infoProgressLane: InfoLiveConsole | null = null;
  public infoDivertLane: Subject<string | null> = new Subject<string | null>();
  public infoDivertLaneMonitor: string | null = null;
  public liveConsoleSearch: Subject<string | undefined> = new Subject<string | undefined>();
  public $changeLaneMonitor: Subject<string> = new BehaviorSubject<string>('Divert Lane Monitor');
  public progressSorting: BehaviorSubject<string> = new BehaviorSubject<string>('recent');

  //Behaviour Subject to store App's Status mode
  statusMode: BehaviorSubject<string> = new BehaviorSubject('in progress');

  constructor(private toastr: ToastrService) {}

  public toogleNav(status: boolean) {
    this.openSideNav.next(status);
  }

  public setLoader(status: boolean, call?: string) {
    setTimeout(() => {
      this.isLoading = status;
    }, 100);
  }

  public setStreamID(id: string | undefined) {
    this.$streamID.next(id);
    this.streamID = id;
  }

  public changeStream(id: string | undefined) {
    this.$changeStreamID.next(id);
    this.streamID = id;
  }

  public toasterErrorMessage(message: string, type?: string) {
    this.toastr.error(message, type);
  }

  public toasterInfoMessage(message?: string, type?: string) {
    this.toastr.info(message, type);
  }
  public changeViewMode(status: boolean) {
    this.viewMode.next(status);
  }

  public searchProgressData(data: any) {
    this.searchProgress.next(data);
  }

  public updateCurrentViewPage(view: any) {
    this.currentViewPage.next(view);
  }

  setStatusMode = (selectedStatus: string) => {
    this.statusMode.next(selectedStatus);
  };

  public triggerViewChange(change: boolean) {
    this.viewChange.next(change);
  }

  public getBreadCrumbsData(data: any) {
    this.trackStreamTree = data;
  }

  public updateStreamTree(remove?: boolean, index?: number | null, tree?: StreamTree | undefined) {
    if (remove) {
      if(index !== null && index !== undefined) {
        this.trackStreamTree.splice(index);
      }
    } else if (tree && tree.type) {
      this.trackStreamTree.push(tree);
    }
    localStorage.setItem('progressDetailTree', JSON.stringify(this.trackStreamTree));
  }

  public progressTypeHandler(type: string) {
    this.progressType = type;
  }

  public updateLaneMonitorLiveConsole(lanes: string[]) {
    this.LaneMonitorLiveConsole.next(lanes);
  }

  public UpdateHistoryViewChecked(view: HistoryView) {
    this.historyViewChecked.next(view);
  }
  public UpdateHistoryView(view: historyViewNav) {
    this.historyView.next(view);
  }

  public updateInfoLiveConsole(data: InfoLiveConsole | null) {
    this.infoLiveConsole.next(data);
    this.infoProgressLane = data;
  }

  public updateDivertLane(data: string | null) {
    this.infoDivertLane.next(data);
    this.infoDivertLaneMonitor = data;
  }

  public searchLiveConsole(text: string | undefined) {
    this.liveConsoleSearch.next(text);
  }

  public changeLaneMonitor(type: string) {
    this.$changeLaneMonitor.next(type)
  }

  public changeProgressSorting(sort: string) {
    this.progressSorting.next(sort);
  }

}
