<div class="bg-white border-gray-200 filter-header">
  <div class="container-fluid filter-header-container">
    <!-- Left Section -->
    <div class="flex items-center">
      <!-- <p class="font-bold text-lg">{{selectedView && selectedView.name ? selectedView.name : 'View'}} - All</p> -->
      <div class="ml-4 relative">
        <button (click)="toggleView()" class="flex w-56 gap-10 items-center justify-between bg-white border border-gray-300 rounded-md px-4 py-2">
          {{selectedView && selectedView.name ? selectedView.name : 'Select View'}}
          <img class="w-5" src="../../../assets/icons/down-arrow.svg" alt="down-arrow">
        </button>
        <!-- Dropdown Content -->
        <div (click)="hideDropdownValues()" [class.filter-header-backdrop]="isProgressView || advanceFilter || isSortBy"></div>
        @if (isProgressView) {
          <div class="absolute mt-1 w-64 bg-white rounded-md shadow-lg z-20">
              <ul class="py-1">
                @for (item of progressView; track $index) {
                  <li (click)="selectView(item)" class="cursor-pointer">
                    <a class="block px-4 py-2 hover:bg-gray-100 me-1" [class.cursor-not-allowed]="item.name === 'Variance View'">
                      {{item?.name}}
                      @if (item.name === "Variance View") {
                        <span class="text-xs">(coming soon)</span>
                      }
                    </a>
                  </li>
                }
              </ul>
            </div>
        }
      </div>
    </div>
    <!-- Center Section -->
    <div class="flex items-center">
      <div class="relative">
        <input 
          type="text" 
          name="search"
          (keyup.enter)="searchProgress()"
          class="border border-gray-300 rounded-md px-4 py-2 pl-5 search-input" 
          [(ngModel)]="searchText"
          placeholder="Search">
        @if (searchText) {
          <mat-icon class="search-input-icon" (click)="clearText()">close</mat-icon>
        }
        <button 
          class="search-input-image" 
          [disabled]="searchText.length === 0"  
          (click)="searchProgress()"
          [class.cursor-not-allowed]="searchText.length === 0"
        >
          <img  src="../../../assets/icons/search_gray.svg" alt="search">
        </button>
      </div>

      @if(selectedView.name === 'Progress View') {
        <div class="ml-4 relative">
          <button 
            (click)="toogleAdvanceFilter()"
            class="filter-header-status bg-white border border-gray-300 rounded-md px-4 py-2"
            [disabled]="!isProgressViewFlag">
            {{ selectedStatus | titlecase }}
            <img class="w-5" src="../../../assets/icons/down-arrow.svg" alt="down-arrow">
          </button>
          <!-- Dropdown Content -->
           @if(advanceFilter && isProgressViewFlag) {
            <div class="absolute mt-1 w-max bg-white rounded-md shadow-lg z-20">
              <ul class="py-1">
                <li *ngFor="let item of statusList; let i = index" (click)="onStatusChange(item)" class="cursor-pointer">
                  <a class="block px-4 py-2 hover:bg-gray-100 me-1">{{ item?.name | titlecase }}</a>
                </li>
              </ul>
            </div>
           }
        </div>
        <div class="ml-4 relative">
          <!-- <button 
          (click)="isSortBy = !isSortBy"
            class="filter-header-status bg-white border border-gray-300 rounded-md px-4 py-2 flex justify-end items-center gap-2"
            [disabled]="!isProgressViewFlag">
            <div class="flex justify-end items-center gap-1">
              <img class="w-4" src="../../../../assets/icons/Soting.svg" alt="sort-arrow">
              Sort By
            </div>
            <img class="w-5" src="../../../../assets/icons/down-arrow.svg" alt="down-arrow">
          </button> -->
          <!-- Dropdown Content -->
           <!-- @if(isSortBy && isProgressViewFlag) {
            <div class="absolute mt-1 w-max bg-white rounded-md shadow-lg z-20">
              <ul class="py-1">
                <li (click)="onSortCange('recent')" class="cursor-pointer">
                  <a [class.bg-gray-100]="selectedSort === 'recent'" class="block px-4 py-2 hover:bg-gray-100">Most Recent</a>
                </li>
                <li (click)="onSortCange('old')" class="cursor-pointer">
                  <a [class.bg-gray-100]="selectedSort === 'old'" class="block px-4 py-2 hover:bg-gray-100">Oldest</a>
                </li>
              </ul>
            </div>
           } -->
        </div>
      }

      <div class="ml-4 relative" *ngIf="tenants.length > 1">
        <button 
          (click)="toggleTenantSwitch()"
          class="filter-header-status bg-white border border-gray-300 rounded-md px-4 py-2"
        >
          {{ selectedTenant | titlecase }}
          <img class="w-5" src="../../../assets/icons/down-arrow.svg" alt="down-arrow">
        </button>
        <!-- Dropdown Content -->
        <div *ngIf="isTenantSwitchFlag" class="absolute mt-1 w-max bg-white rounded-md shadow-lg z-10">
          <ul class="py-1">
            <li *ngFor="let item of tenants; let i = index" (click)="onTenantChange(item)" class="cursor-pointer">
              <a class="block px-4 py-2 hover:bg-gray-100 me-1">{{ item | titlecase }}</a>
            </li>
          </ul>
        </div>
      </div>
      

    </div>
    <!-- Right Section -->
    <!-- <div class="flex items-center">
      <button 
        (click)="toggleDesktopView(this.viewType[0])"
        [disabled]="desktopView === 'Spatial View'"
        [class.view-btn-active]="desktopView === this.viewType[0]"
        class="flex gap-3 text-xs items-center view-btn bg-white border border-e-0 border-gray-300 rounded-tl-md rounded-bl-md px-4 py-2">
        <img [src]="desktopView === this.viewType[0] ? '../../../assets/icons/cockpit_white.svg' : '../../../assets/icons/cockpit_gray.svg'" alt="cockpit">
        {{this.viewType[0]}}
      </button>
      <button 
      [class.view-btn-active]="desktopView === this.viewType[1]"
      (click)="toggleDesktopView(this.viewType[1])"
        class="flex gap-3 view-btn text-xs items-center bg-white border border-gray-300 rounded-tr-md rounded-br-md px-4 py-2">
        <img [src]="desktopView !== this.viewType[1] ? '../../../assets/icons/birdseye.svg' : '../../../assets/icons/birdseye_gray.svg'" alt="bird's eye">
        {{this.viewType[1]}}
      </button>

    </div> -->
  </div>
</div>
